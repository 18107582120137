// Dependencies
import { useState, useEffect } from 'react';

// Style Imports
import s from "../../../../styles/lander/animations/removal_types/SearchResultsAnimation.module.css";

// Constants
const searchResults = [
    { id: 1, title: '\'Oh noooo\' - Alex Fontaine Leaked Text Messages Show Accident That Led to Embarrassing Slipup', platform: 'Google Search', delay: 1500 },
    { id: 2, title: '\"Oh noooo\" - Behind the Scenes of Embarrassing Moment that Led to Alex Fontaine Leak', platform: 'Bing', delay: 3000 },
    { id: 3, title: 'Alex Fontaine Slip Leaked 2025', platform: 'Google Search', delay: 4500 },
    { id: 4, title: '\'Oh noooo\' - Alex Fontaine Leaked Text Messages Show Accident That Led to Embarrassing Slipup', platform: 'Yahoo', delay: 6000 },
];

// Helper Function
const getPlatform = (platform) => {
    switch (platform) {
        case 'Google Search':
            return (
                <svg width="800px" height="800px" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.75,16A7.7446,7.7446,0,0,1,8.7177,18.6259L4.2849,22.1721A13.244,13.244,0,0,0,29.25,16" fill="#00ac47"/>
                    <path d="M23.75,16a7.7387,7.7387,0,0,1-3.2516,6.2987l4.3824,3.5059A13.2042,13.2042,0,0,0,29.25,16" fill="#4285f4"/>
                    <path d="M8.25,16a7.698,7.698,0,0,1,.4677-2.6259L4.2849,9.8279a13.177,13.177,0,0,0,0,12.3442l4.4328-3.5462A7.698,7.698,0,0,1,8.25,16Z" fill="#ffba00"/>
                    <polygon fill="#2ab2db" points="8.718 13.374 8.718 13.374 8.718 13.374 8.718 13.374"/>
                    <path d="M16,8.25a7.699,7.699,0,0,1,4.558,1.4958l4.06-3.7893A13.2152,13.2152,0,0,0,4.2849,9.8279l4.4328,3.5462A7.756,7.756,0,0,1,16,8.25Z" fill="#ea4435"/>
                    <polygon fill="#2ab2db" points="8.718 18.626 8.718 18.626 8.718 18.626 8.718 18.626"/>
                    <path d="M29.25,15v1L27,19.5H16.5V14H28.25A1,1,0,0,1,29.25,15Z" fill="#4285f4"/>
                </svg>
            );
        case 'Bing':
            return (
                <svg width="800px" height="800px" viewBox="-32 0 320 320" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
                    <path d="M95.055,82.908 L127.636,153.555 L175.78,175.332 L2.125,266.695 L73.279,203.267 L73.279,22.449 L0,0 L0,268.932 L72.83,319.986 L256,210.88 L256,132.227 L95.055,82.908" fill="#F4BD27"></path>
                </svg>
            );
        case 'Yahoo':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" aria-label="Yahoo!" role="img" viewBox="0 0 512 512" fill="#ffffff">
                    <rect width="512" height="512" rx="15%" fill="#5f01d1"/>
                    <g fill="#ffffff">
                        <path d="M203 404h-62l25-59-69-165h63l37 95 37-95h62m58 76h-69l62-148h69"/>
                        <circle cx="303" cy="308" r="38"/>
                    </g>
                </svg>
            );
        default:
            return <></>;
    }
}

// FakeResult Component
const SearchResult = ({
    title,
    platform,
    delay,
    color,
    results = searchResults
}) => {
    const [removing, setRemoving] = useState(false);
    const [removed, setRemoved] = useState(false);

    useEffect(() => {
        const removeTimeout = setTimeout(() => {
            setRemoving(true);
            
            setTimeout(() => {
                setRemoving(false);
                setRemoved(true);
            }, 500);
        }, delay);

        return () => clearTimeout(removeTimeout);
    }, [delay]);

    const renderResultContent = () => {
        if (removed) {
            return (
                <div className={`${s.result_info} ${color === 'light' ? s.light : ''}`} style={{'margin': 'auto 0'}}>
                    <p className="mva">Search result removed</p>
                </div>
            );
        }
        return (
            <div className={`${s.result_info} ${color === 'light' ? s.light : ''}`}>
                <div className={`${s.result_info_header} ${color === 'light' ? s.light : ''}`}>
                    <>{getPlatform(platform)}</>
                    <p>{platform}</p>
                </div>
                <p>{title}</p>
            </div>
        );
    };

    return (
        <div className={`${s.result} ${removing ? s.removing : ''} ${color === 'light' ? s.light : ''}`}>
            {(removed) && 
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <path d="M16.5,22.5h3v3h-3Zm0-12h3v9h-3ZM17.985,3A15,15,0,1,0,33,18,14.993,14.993,0,0,0,17.985,3ZM18,30A12,12,0,1,1,30,18,12,12,0,0,1,18,30Z" transform="translate(-3 -3)" fill="#dbdbdb"/>
                </svg>
            }
            {renderResultContent()}
        </div>
    );
};

// Main Component
export default function SearchResultsAnimation({
    color = "dark"
}) {
    return (
        <>
            <div className={`${s.search} ${color === 'light' ? s.light : ''}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18.129" height="18.128" viewBox="0 0 18.129 18.128">
                    <path d="M-17104.152-4775.091l-3.42-3.43a8.148,8.148,0,0,1-5.238,1.9,8.2,8.2,0,0,1-8.189-8.19,8.2,8.2,0,0,1,8.189-8.189,8.2,8.2,0,0,1,8.189,8.189,8.148,8.148,0,0,1-1.893,5.229l3.422,3.429a.747.747,0,0,1,0,1.061.749.749,0,0,1-.529.219A.744.744,0,0,1-17104.152-4775.091Zm-15.35-9.719a6.7,6.7,0,0,0,6.691,6.69,6.7,6.7,0,0,0,6.689-6.69,6.7,6.7,0,0,0-6.689-6.69A6.7,6.7,0,0,0-17119.5-4784.81Z" transform="translate(17121 4793)" fill="#b3b3b3"/>
                </svg>
                <p>Alex Fontaine leaked</p>
            </div>
            <div className={`${s.results} ${color === 'light' ? s.light : ''}`}>
                {searchResults.map((result) => (
                    <SearchResult 
                        key={result.id}
                        title={result.title}
                        platform={result.platform}
                        delay={result.delay}
                        color={color}
                    />
                ))}
            </div>
        </>
    );
} 