// Dependencies
import { useState, useEffect } from 'react';

// Style Imports
import s from "../../../../styles/lander/animations/removal_types/RelatedSearchesAnimation.module.css";

// Helper Component
const SearchResult = ({ text, delay }) => {
    const [removing, setRemoving] = useState(false);
    const [removed, setRemoved] = useState(false);

    useEffect(() => {
        if (delay <= 0) return;
        const removeTimeout = setTimeout(() => {
            setRemoving(true);
            
            setTimeout(() => {
                setRemoving(false);
                setRemoved(true);
            }, 500);
        }, delay);

        return () => clearTimeout(removeTimeout);
    }, [delay]);

    return (
        <div className={`${s.result} ${(removing) ? s.removing : ''} ${(removed) ? s.removed : ''}`}>
            <p>Alex Fontaine <span>{text}</span></p>
        </div>
    );
};

// Functional Component
export default function RelatedSearchesAnimation() {
    const searchResults = [
        { id: 1, text: 'ripoff', delay: 1500 },
        { id: 2, text: 'latest news', delay: 0 }, // 0 = keep result
        { id: 3, text: 'lawsuit', delay: 3000 },
        { id: 4, text: 'loser', delay: 4500 },
        { id: 5, text: 'charity', delay: 0 }, // 0 = keep result
        { id: 6, text: 'giveaway', delay: 0 }, // 0 = keep result
    ];

    // Return layout
    return (
        <>
            <div className={s.search}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18.129" height="18.128" viewBox="0 0 18.129 18.128">
                    <path d="M-17104.152-4775.091l-3.42-3.43a8.148,8.148,0,0,1-5.238,1.9,8.2,8.2,0,0,1-8.189-8.19,8.2,8.2,0,0,1,8.189-8.189,8.2,8.2,0,0,1,8.189,8.189,8.148,8.148,0,0,1-1.893,5.229l3.422,3.429a.747.747,0,0,1,0,1.061.749.749,0,0,1-.529.219A.744.744,0,0,1-17104.152-4775.091Zm-15.35-9.719a6.7,6.7,0,0,0,6.691,6.69,6.7,6.7,0,0,0,6.689-6.69,6.7,6.7,0,0,0-6.689-6.69A6.7,6.7,0,0,0-17119.5-4784.81Z" transform="translate(17121 4793)" fill="#b3b3b3"/>
                </svg>
                <p>Alex Fontaine</p>
            </div>
            <p className={s.result_txt}>Searches related to Alex Fontaine:</p>
            <div className={s.results}>
                {searchResults.map((result) => (
                    <SearchResult 
                        key={result.id}
                        text={result.text}
                        delay={result.delay}
                    />
                ))}
            </div>
        </>
    );
} 