// Dependencies
import { useState, useEffect } from 'react';

// Style Imports
import "../../styles/lander/rmv_typecards.css";

// Component Imports
import ImagesAnimation from './Animations/RemovalTypes/ImagesAnimation.jsx';
import VideosAnimation from './Animations/RemovalTypes/VideosAnimation.jsx';
import SearchResultsAnimation from './Animations/RemovalTypes/SearchResultsAnimation.jsx';
import WebsitesAnimation from './Animations/RemovalTypes/WebsitesAnimation.jsx';
import SocialMediaAnimation from './Animations/RemovalTypes/SocialMediaAnimation.jsx';
import BlogsAnimation from './Animations/RemovalTypes/BlogsAnimation.jsx';
import FakeAccountsAnimation from './Animations/RemovalTypes/FakeAccountsAnimation.jsx';
import LeakedContentAnimation from './Animations/RemovalTypes/LeakedContentAnimation.jsx';
import RelatedSearchesAnimation from './Animations/RemovalTypes/RelatedSearchesAnimation.jsx';

// Constants
const CARDS = [
	{
		title: "Images",
		animation: <ImagesAnimation />
	},
	{
		title: "Videos",
		animation: <VideosAnimation />
	},
	{
		title: "Search Results",
		animation: <SearchResultsAnimation />
	},
	{
		title: "Websites & Webpages",
		animation: <WebsitesAnimation />
	},
	{
		title: "Social Media Posts",
		animation: <SocialMediaAnimation />
	},
	{
		title: "Blogs",
		animation: <BlogsAnimation />
	},
	{
		title: "Fake Accounts",
		animation: <FakeAccountsAnimation />
	},
	{
		title: "Leaked Content",
		animation: <LeakedContentAnimation />
	},
	{
		title: "Related Searches",
		animation: <RelatedSearchesAnimation />
	}
]

// Functional Component
export default function ContentRemovalTypeCards() {
	// States
	const [activeCard, setActiveCard] = useState(0);
	const [userInteracted, setUserInteracted] = useState(false);

	// Cycle every 8 seconds if user hasn't interacted
	useEffect(() => {
		if (userInteracted) return; // Stop auto-cycling if user has clicked
		const interval = setInterval(() => {
			setActiveCard((current) => (current + 1) % CARDS.length);
		}, 8000);
		return () => clearInterval(interval);
	}, [userInteracted]);

	// Handlers
	const handleCardClick = (idx) => {
		setUserInteracted(true);
		setActiveCard(idx);
	};

	// Return layout
	return (
		<div className="crtp-cards">
			<div className="l">
				{CARDS.map((card, idx) => (
					<button className={(activeCard === idx) ? "sel" : ""} key={`crtp-card-${idx}`} onClick={()=>{handleCardClick(idx)}}>{card.title}</button>
				))}
			</div>
			<div className="r">
				<div className="in">
					{CARDS[activeCard].animation}
				</div>
			</div>
		</div>
	);
} 