// Dependencies
import { useState, useEffect } from 'react';

// Style Imports
import s from "../../../../styles/lander/animations/removal_types/ImagesAnimation.module.css";

// Image Imports
import img_1 from '../../../../assets/img/idx/anim/img_1.webp';
import img_2 from '../../../../assets/img/idx/anim/img_2.webp';
import img_3 from '../../../../assets/img/idx/anim/img_3.webp';
import img_4 from '../../../../assets/img/idx/anim/img_4.webp';
import img_5 from '../../../../assets/img/idx/anim/img_5.webp';
import img_6 from '../../../../assets/img/idx/anim/img_6.webp';
import img_7 from '../../../../assets/img/idx/anim/img_7.webp';
import img_8 from '../../../../assets/img/idx/anim/img_8.webp';
import img_9 from '../../../../assets/img/idx/anim/img_9.webp';

// Constants
const imageResults = [
    {
        id: 1,
        image: img_1,
        delay: 1500,
    },
    {
        id: 2,
        image: img_2,
        delay: 2000,
    },
    {
        id: 3,
        image: img_3,
        delay: 2500,
    },
    {
        id: 4,
        image: img_4,
        delay: 3000,
    },
    {
        id: 5,
        image: img_5,
        delay: 3500,
    },
    {
        id: 6,
        image: img_6,
        delay: 4000,
    },
    {
        id: 7,
        image: img_7,
        delay: 4500,
    },
    {
        id: 8,
        image: img_8,
        delay: 5000,
    },
    {
        id: 9,
        image: img_9,
        delay: 5500,
    },
]

// Helper Component 
function ImageResult({ id, image, delay }) {
    const [removing, setRemoving] = useState(false);
    const [removed, setRemoved] = useState(false);
    useEffect(() => {
        if (delay > 0) {
            const removeTimeout = setTimeout(() => {
                setRemoving(true);
                setTimeout(() => {
                    setRemoving(false);
                    setRemoved(true);
                }, 500);
            }, delay);
            return () => clearTimeout(removeTimeout);
        }
    }, [delay]);

    return (
        <>
            {(removed) ?
                <div key={`smrm-anim-lnd-idk-${id}`} className={s.removed}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        <path d="M16.5,22.5h3v3h-3Zm0-12h3v9h-3ZM17.985,3A15,15,0,1,0,33,18,14.993,14.993,0,0,0,17.985,3ZM18,30A12,12,0,1,1,30,18,12,12,0,0,1,18,30Z" transform="translate(-3 -3)" fill="#dbdbdb"/>
                    </svg>
                </div>
                :
                <div key={`sm-anim-lnd-idk-${id}`} className={`${s.post} ${removing ? s.removing : ''}`}>
                    <img src={image.src} alt="Post" />
                </div>
            }
        </>
    );
}
    
// Functional Component
export default function ImagesAnimation() {
    // Return layout
    return (
        <>
            <div className={s.search}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18.129" height="18.128" viewBox="0 0 18.129 18.128">
                    <path d="M-17104.152-4775.091l-3.42-3.43a8.148,8.148,0,0,1-5.238,1.9,8.2,8.2,0,0,1-8.189-8.19,8.2,8.2,0,0,1,8.189-8.189,8.2,8.2,0,0,1,8.189,8.189,8.148,8.148,0,0,1-1.893,5.229l3.422,3.429a.747.747,0,0,1,0,1.061.749.749,0,0,1-.529.219A.744.744,0,0,1-17104.152-4775.091Zm-15.35-9.719a6.7,6.7,0,0,0,6.691,6.69,6.7,6.7,0,0,0,6.689-6.69,6.7,6.7,0,0,0-6.689-6.69A6.7,6.7,0,0,0-17119.5-4784.81Z" transform="translate(17121 4793)" fill="#b3b3b3"/>
                </svg>
                <p>Alex Fontaine leaked</p>
            </div>
            <div className={s.feed}>
                {imageResults.map((post) => (
                    <ImageResult key={`smrm-anim-lnd-irpidk-${post.id}`} id={post.id} image={post.image} delay={post.delay} />
                ))}
            </div>
        </>
    );
} 