// Dependencies
import { useState, useEffect } from 'react';

// Image Imports
import pfp from '../../../../assets/img/idx/anim/ig_alexf.webp';

// Style Imports
import s from "../../../../styles/lander/animations/removal_types/FakeAccountsAnimation.module.css";

// Fake Account Component
const FakeAccount = ({ username, delay }) => {
    const [removing, setRemoving] = useState(false);
    const [removed, setRemoved] = useState(false);

    useEffect(() => {
        const removeTimeout = setTimeout(() => {
            setRemoving(true);
            
            setTimeout(() => {
                setRemoving(false);
                setRemoved(true);
            }, 500);
        }, delay);

        return () => clearTimeout(removeTimeout);
    }, [delay]);

    const renderAccountContent = () => {
        if (removed) {
            return (
                <div className={s.account_info} style={{'margin': 'auto 0'}}>
                    <p className="mva">Impersonator removed</p>
                </div>
            );
        }

        return (
            <div className={s.account_info}>
                <div className={s.account_info_header}>
                    <p>Alex Fontaine</p>
                </div>
                <p>{username}</p>
            </div>
        );
    };

    return (
        <div className={`${s.account} ${removing ? s.removing : ''}`}>
            {(removed) ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <path d="M16.5,22.5h3v3h-3Zm0-12h3v9h-3ZM17.985,3A15,15,0,1,0,33,18,14.993,14.993,0,0,0,17.985,3ZM18,30A12,12,0,1,1,30,18,12,12,0,0,1,18,30Z" transform="translate(-3 -3)" fill="#dbdbdb"/>
                </svg>
            ) : (
                <img src={pfp.src} alt="Pfp" />
            )}
            {renderAccountContent()}
        </div>
    );
};

// Main Component
export default function FakeAccountsAnimation() {
    const fakeAccounts = [
        { id: 1, username: '@a1exfontaine', delay: 1500 },
        { id: 2, username: '@alex.fontaine', delay: 3000 },
        { id: 3, username: '@alexxfontaine', delay: 4500 },
        { id: 4, username: '@alexfonta1ne', delay: 6000 },
    ];

    return (
        <>
            <div className={s.search}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18.129" height="18.128" viewBox="0 0 18.129 18.128">
                    <path d="M-17104.152-4775.091l-3.42-3.43a8.148,8.148,0,0,1-5.238,1.9,8.2,8.2,0,0,1-8.189-8.19,8.2,8.2,0,0,1,8.189-8.189,8.2,8.2,0,0,1,8.189,8.189,8.148,8.148,0,0,1-1.893,5.229l3.422,3.429a.747.747,0,0,1,0,1.061.749.749,0,0,1-.529.219A.744.744,0,0,1-17104.152-4775.091Zm-15.35-9.719a6.7,6.7,0,0,0,6.691,6.69,6.7,6.7,0,0,0,6.689-6.69,6.7,6.7,0,0,0-6.689-6.69A6.7,6.7,0,0,0-17119.5-4784.81Z" transform="translate(17121 4793)" fill="#b3b3b3"/>
                </svg>
                <p>Alex Fontaine</p>
            </div>
            <div className={s.accounts}>
                {/* Verified Account */}
                <div className={s.account}>
                    <img src={pfp.src} alt="Pfp" />
                    <div className={s.account_info}>
                        <div className={s.account_info_header}>
                            <p>Alex Fontaine</p>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><polygon fill="#42a5f5" points="29.62,3 33.053,8.308 39.367,8.624 39.686,14.937 44.997,18.367 42.116,23.995 45,29.62 39.692,33.053 39.376,39.367 33.063,39.686 29.633,44.997 24.005,42.116 18.38,45 14.947,39.692 8.633,39.376 8.314,33.063 3.003,29.633 5.884,24.005 3,18.38 8.308,14.947 8.624,8.633 14.937,8.314 18.367,3.003 23.995,5.884"/><polygon fill="#fff" points="21.396,31.255 14.899,24.76 17.021,22.639 21.428,27.046 30.996,17.772 33.084,19.926"/></svg>
                        </div>
                        <p>@alexfontaine</p>
                    </div>
                </div>
                
                {/* Fake Accounts */}
                {fakeAccounts.map((account) => (
                    <FakeAccount 
                        key={account.id}
                        username={account.username}
                        delay={account.delay}
                    />
                ))}
            </div>
        </>
    );
} 