// Dependencies
import { useState, useEffect } from 'react';

// Image Imports
import thumbnail_1 from '../../../../assets/img/idx/anim/vid_1.webp';
import thumbnail_2 from '../../../../assets/img/idx/anim/vid_2.webp';
import thumbnail_3 from '../../../../assets/img/idx/anim/vid_3.webp';

// Style Imports
import s from '../../../../styles/lander/animations/removal_types/VideosAnimation.module.css';

// Constants
const badVideos = [
    {
        id: 1,
        thumbnail: thumbnail_1,
        title: "Ecom Mastery Module 1",
        views: "1,500 Views",
        delay: 1500,
    },
    {
        id: 2,
        thumbnail: thumbnail_2,
        title: "Ecom Mastery Module 2",
        views: "1,500 Views",
        delay: 3000,
    },
    {
        id: 3,
        thumbnail: thumbnail_3,
        title: "Ecom Mastery Module 3",
        views: "1,500 Views",
        delay: 4500,
    }
]

// Helper Component
const VideoContent = ({ thumbnail, title, views, delay }) => {
    const [removing, setRemoving] = useState(false);
    const [removed, setRemoved] = useState(false);

    useEffect(() => {
        const removeTimeout = setTimeout(() => {
            setRemoving(true);
            
            setTimeout(() => {
                setRemoving(false);
                setRemoved(true);
            }, 500);
        }, delay);

        return () => clearTimeout(removeTimeout);
    }, [delay]);

    return (
        <>
            {(removed) ? (
                <div className={s.result}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        <path d="M16.5,22.5h3v3h-3Zm0-12h3v9h-3ZM17.985,3A15,15,0,1,0,33,18,14.993,14.993,0,0,0,17.985,3ZM18,30A12,12,0,1,1,30,18,12,12,0,0,1,18,30Z" transform="translate(-3 -3)" fill="#dbdbdb"/>
                    </svg>
                    <div className={s.result_info} style={{'margin': 'auto 0'}}>
                        <p className="mva">Video no longer available</p>
                    </div>
                </div>
            ) : (
                <div className={`${s.video} ${(removing) ? s.removing : ''}`}>
                    <img className={s.thumbnail} src={thumbnail.src} alt="Thumbnail" />
                    <div className={s.video_info}>
                        <p>{title}</p>
                        <p>{views}</p>
                    </div>
                </div>
            )}
        </>
    );
};

// Main Component
export default function VideosAnimation() {
    return (
        <>
            <div className={s.search}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18.129" height="18.128" viewBox="0 0 18.129 18.128">
                    <path d="M-17104.152-4775.091l-3.42-3.43a8.148,8.148,0,0,1-5.238,1.9,8.2,8.2,0,0,1-8.189-8.19,8.2,8.2,0,0,1,8.189-8.189,8.2,8.2,0,0,1,8.189,8.189,8.148,8.148,0,0,1-1.893,5.229l3.422,3.429a.747.747,0,0,1,0,1.061.749.749,0,0,1-.529.219A.744.744,0,0,1-17104.152-4775.091Zm-15.35-9.719a6.7,6.7,0,0,0,6.691,6.69,6.7,6.7,0,0,0,6.689-6.69,6.7,6.7,0,0,0-6.689-6.69A6.7,6.7,0,0,0-17119.5-4784.81Z" transform="translate(17121 4793)" fill="#b3b3b3"/>
                </svg>
                <p>Ecom Mastery full course modules</p>
            </div>
            <div className={s.results}>
                {badVideos.map((video) => (
                    <VideoContent 
                        key={video.id}
                        thumbnail={video.thumbnail}
                        title={video.title}
                        views={video.views}
                        delay={video.delay}
                    />
                ))}
            </div>
        </>
    );
}