// Dependencies
import { useState, useEffect } from 'react';

// Style Imports
import s from "../../../../styles/lander/animations/removal_types/SocialMediaAnimation.module.css";

// Image Imports
import socials_1 from '../../../../assets/img/idx/anim/socials_1.webp';
import socials_2 from '../../../../assets/img/idx/anim/socials_2.webp';
import socials_3 from '../../../../assets/img/idx/anim/socials_3.webp';
import socials_4 from '../../../../assets/img/idx/anim/socials_4.webp';

// Constants
const socialPosts = [
    {
        id: 1,
        image: socials_1,
        delay: 1000,
    },
    {
        id: 2,
        image: socials_2,
        delay: 2500,
    },
    {
        id: 3,
        image: socials_3,
        delay: 3750,
    },
    {
        id: 4,
        image: socials_4,
        delay: 5000,
    }
]

// Helper Component 
function SocialPost({ id, image, delay, color }) {
    const [removing, setRemoving] = useState(false);
    const [removed, setRemoved] = useState(false);
    useEffect(() => {
        if (delay > 0) {
            const removeTimeout = setTimeout(() => {
                setRemoving(true);
                setTimeout(() => {
                    setRemoving(false);
                    setRemoved(true);
                }, 500);
            }, delay);
            return () => clearTimeout(removeTimeout);
        }
    }, [delay]);

    return (
        <>
            {(removed) ?
                <div key={`smrm-anim-lnd-idk-${id}`} className={`${s.removed} ${color === 'light' ? s.light : ''}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                        <path d="M16.5,22.5h3v3h-3Zm0-12h3v9h-3ZM17.985,3A15,15,0,1,0,33,18,14.993,14.993,0,0,0,17.985,3ZM18,30A12,12,0,1,1,30,18,12,12,0,0,1,18,30Z" transform="translate(-3 -3)" fill="#dbdbdb"/>
                    </svg>
                </div>
                :
                <div key={`sm-anim-lnd-idk-${id}`} className={`${s.post} ${removing ? s.removing : ''} ${color === 'light' ? s.light : ''}`}>
                    <img src={image.src} alt="Post" />
                </div>
            }
        </>
    );
}
    
// Functional Component
export default function SocialMediaAnimation({
    color = "dark"
}) {
    const [removing, setRemoving] = useState(false);
    const [removed, setRemoved] = useState(false);

    useEffect(() => {
        const removeTimeout = setTimeout(() => {
            setRemoving(true);
            setTimeout(() => {
                setRemoving(false);
                setRemoved(true);
            }, 800);
        }, 2500);
        return () => clearTimeout(removeTimeout);
    }, []);

    // Return layout
    return (
        <div className={`${s.device} ${color === 'light' ? s.light : ''}`}>
            <div className={`${s.search} ${color === 'light' ? s.light : ''}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18.129" height="18.128" viewBox="0 0 18.129 18.128">
                    <path d="M-17104.152-4775.091l-3.42-3.43a8.148,8.148,0,0,1-5.238,1.9,8.2,8.2,0,0,1-8.189-8.19,8.2,8.2,0,0,1,8.189-8.189,8.2,8.2,0,0,1,8.189,8.189,8.148,8.148,0,0,1-1.893,5.229l3.422,3.429a.747.747,0,0,1,0,1.061.749.749,0,0,1-.529.219A.744.744,0,0,1-17104.152-4775.091Zm-15.35-9.719a6.7,6.7,0,0,0,6.691,6.69,6.7,6.7,0,0,0,6.689-6.69,6.7,6.7,0,0,0-6.689-6.69A6.7,6.7,0,0,0-17119.5-4784.81Z" transform="translate(17121 4793)" fill="#b3b3b3"/>
                </svg>
                <p>@alexfontaine</p>
            </div>
            <div className={`${s.feed} ${color === 'light' ? s.light : ''}`}>
                {socialPosts.map((post) => (
                    <SocialPost color={color} id={post.id} image={post.image} delay={post.delay} />
                ))}
            </div>
        </div>
    );
} 